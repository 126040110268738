.container {
  .title {
    font-size: var(--text-xxl);
    text-align: left;
    font-family: "Blenny";
    color: #ffff;
    line-height: 1;
  }

  .left-box {
    background-image: linear-gradient(1deg, #330d69 0%, #30c9cd 100%);
    position: relative;
  }

  .right-box {
    background-image: linear-gradient(1deg, #12d6df 0%, #f70fff 100%);
    position: relative;

    .doubleshot {
      max-width: 150px;
    }

    .text {
      font-family: "CooperStdBlack";
      font-size: var(--text-20);
      line-height: 1;
    }
  }

  .content {
    min-height: 400px;
    position: relative;
    z-index: 2;
  
    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: -1;
    }

    .number {
      font-size: var(--text-xxl);
      line-height: 1;
      font-family: "Blenny";
      background: -webkit-linear-gradient(#ff9794, #fce37b);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title {
      font-family: "Blenny";
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 800;
      text-align: center;
      font-size: var(--text-lg);
      line-height: 0.9;
    }

    .description {
      color: #ffffff;
      font-size: var(--text-sm);
      line-height: 15px;
      font-family: "CooperStdBlack";
      .yellow {
        color: #f7fa19;
      }
    }
  }

  .right-box {
    .number {
      display: flex;
      position: absolute;
      top: 6rem;
      left: 1rem;
    }

    .people-img {
      width: 7rem;
      transform: translate(-4.7rem, -1.5rem);
    }
    .arrow-img {
      position: absolute;
      width: 8rem;
      top: 6rem;
    }
  }

  @media (max-width: 1200px) {
    .title {
      font-size: 40px;
    }
  }

  @media (max-width: 576px) {
    .title {
      font-size: 32px;
    }
  }
}
