@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap");

.App {
  text-align: center;
}

body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccc;
    border-radius: 10px;
  }
}

:root {
  --main-bg-color: #427e63;
  --roadmap-bg-color: #a670bd;
  --manifesto-bg-color: #903061;
  --crip-token-bg-color: #377b7f;
  --faq-bg-color: #959940;
  --team-bg-color: #548956;
  --text-diaplay1: 108px;
  --text-diaplay2: 72px;
  --text-xxl: 64px;
  --text-40: 40px;
  --text-xl: 32px;
  --text-lg: 24px;
  --text-20: 20px;
  --text-md: 16px;
  --text-sm: 14px;
  --text-xs: 12px;
}

@font-face {
  font-family: Acier-display;
  src: url("./assets/fonts/Acier-DisplaySolid.woff");
}

@font-face {
  font-family: Blenny;
  src: url("./assets/fonts/Blenny_Trial_Blk.ttf");
}
@font-face {
  font-family: Barricada;
  src: url("./assets/fonts/Barricada W01 Regular.woff");
}

@font-face {
  font-family: CooperStdBlack;
  src: url("./assets/fonts/CooperStdBlack.ttf");
}

img {
  width: 100%;
}
.box-shadow {
  filter: drop-shadow(0.052px 0.999px 2px #040300);
}
.text-lightyellow {
  color: #f2fb04;
}
.text-lightred {
  color: #fc6104;
}
.text-lightred-100 {
  color: #ff5400;
}
.text-lightgreen {
  color: #1bfa04;
}
.text-green {
  color: #5cff00;
}
.text-orange {
  color: #ffc300;
}
.text-orange-100 {
  color: #fba002;
}
.text-orange-500 {
  color: #fc7100;
}
.text-yellow {
  color: #f3ce13 !important;
}
.text-yellow-100 {
  color: #fada01 !important;
}
.text-yellow-200 {
  color: #ffe400 !important;
}
.text-red {
  color: #d20325;
}

.font-acierdisplay {
  font-family: "Acier-display" !important;
}
.font-blenny {
  font-family: "Blenny" !important;
}
.font-cooperBlack {
  font-family: "CooperStdBlack";
}
.font-arial {
  font-family: Arial;
}
.font-barricada {
  font-family: "Barricada";
}
.font-chelsea {
  font-family: "Chelsea Market", cursive;
}

.fontsize10px {
  font-size: 10px;
}
.fontsize11px {
  font-size: 11px;
}
.fontsize12px {
  font-size: 12px;
}
.fontsize13px {
  font-size: 13px;
}
.fontsize14px {
  font-size: 14px;
}
.fontsize15px {
  font-size: 15px;
}
.fontsize16px {
  font-size: 16px;
}
.fontsize17px {
  font-size: 17px;
}
.fontsize18px {
  font-size: 18px;
}
.fontsize19px {
  font-size: 19px;
}
.fontsize20px {
  font-size: 20px;
}
.fontsize21px {
  font-size: 21px;
}
.fontsize22px {
  font-size: 22px;
}
.fontsize23px {
  font-size: 23px;
}
.fontsize24px {
  font-size: 24px;
}
.fontsize25px {
  font-size: 25px;
}
.fontsize26px {
  font-size: 26px;
}
.fontsize27px {
  font-size: 27px;
}
.fontsize28px {
  font-size: 28px;
}
.fontsize29px {
  font-size: 29px;
}
.fontsize30px {
  font-size: 30px;
}
.fontsize31px {
  font-size: 31px;
}
.fontsize32px {
  font-size: 32px;
}
.fontsize33px {
  font-size: 33px;
}
.fontsize34px {
  font-size: 34px;
}
.fontsize35px {
  font-size: 35px;
}
.fontsize36px {
  font-size: 36px;
}
.fontsize37px {
  font-size: 37px;
}
.fontsize38px {
  font-size: 38px;
}
.fontsize39px {
  font-size: 39px;
}
.fontsize40px {
  font-size: 40px;
}
.fontsize41px {
  font-size: 41px;
}
.fontsize42px {
  font-size: 42px;
}
.fontsize43px {
  font-size: 43px;
}
.fontsize44px {
  font-size: 44px;
}
.fontsize45px {
  font-size: 45px;
}

.mt-6 {
  margin-top: 4.5rem;
}
.mt-7 {
  margin-top: 6rem;
}
.mt-8 {
  margin-top: 7.5rem;
}

.pt-6 {
  padding-top: 4.5rem;
}
.pt-7 {
  padding-top: 6rem;
}
.pt-8 {
  padding-top: 7.5rem;
}
.pt-9 {
  padding-top: 9rem;
}
.pb-6 {
  padding-bottom: 4.5rem;
}
.pb-7 {
  padding-bottom: 6rem;
}
.pb-8 {
  padding-bottom: 7.5rem;
}
.pb-9 {
  padding-bottom: 9rem;
}

.letterspace1px {
  letter-spacing: 1px;
}
.letterspace2px {
  letter-spacing: 2px;
}
.letterspace3px {
  letter-spacing: 3px;
}
.letterspace4px {
  letter-spacing: 4px;
}
.letterspace5px {
  letter-spacing: 5px;
}
.letterspace6px {
  letter-spacing: 6px;
}
.letterspace8px {
  letter-spacing: 8px;
}
.letterspace9px {
  letter-spacing: 9px;
}
.letterspace12px {
  letter-spacing: 12px;
}

.w-90 {
  width: 90%;
}
