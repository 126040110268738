.container {
    background: var(--crip-token-bg-color);
    min-height: 100vh;
  
    .main {
      padding: 4rem 0 10rem;
  
      .title {
        font-size: var(--text-diaplay2);
        text-transform: uppercase;
      }
  
      .text-sub {
        h3 {
          font-size: var(--text-xl);
          font-weight: 700;
        }
      }
  
      .description {
        font-size: var(--text-lg);
        word-spacing: 3px;
        line-height: 1.1;
      }
  
      .text-large {
          font-size: var(--text-xl);
      }
    }
  
    @media (max-width: 768px) {
      .main {
        padding: 0 0 50px;
  
        .title {
          font-size: var(--text-xl);
        }
      }
    }
  }
  