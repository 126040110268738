.container {
  background: var(--faq-bg-color);
  min-height: 100vh;

  .main {
    padding: 4rem 0 10rem;

    .title {
      font-size: var(--text-diaplay2);
      text-transform: uppercase;
      filter: drop-shadow(0.052px 0.999px 2px #040300);
    }

    .text-sub {
      h3 {
        font-size: var(--text-xl);
        font-weight: 700;
      }
    }

    .description {
      font-size: var(--text-lg);
      word-spacing: 1px;
    }

    .discord-icon {
      width: 40px;
      height: 40px;
    }
    .faq-sec {
      background: transparent;
      border: 0px;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 1.5rem;

      button {
        background: #610273;
        color: #fff;
        text-transform: uppercase;
        font-family: "Acier-display";
        font-size: var(--text-lg);
        letter-spacing: 0.7px;
        box-shadow: 0px 0px;
        border-radius: 5px;
        padding-top: 20px;

        &::after {
          content: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main {
      padding: 0 0 50px;

      .title {
        font-size: var(--text-xl);
      }
    }
  }
}
