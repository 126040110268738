.container {
  padding-top: 100px;
  font-family: "Barricada";
  color: #f2fb04;
  text-align: left;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 24px;

  .coffee-wars {
    font-size: 40px;
  }

  .green {
    font-size: 40px;
  }

  .left-container {
    padding-top: 5rem;
  }

  .banner-img {
    div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--text-lg);
    }
  }

  @media (max-width: 1200px) {
    padding-top: 0;
    font-size: 25px;

    .coffee-wars {
      font-size: 35px;
    }

    .green {
      font-size: 35px;
    }

    .left-container {
      padding-top: 0rem;
    }
  }

  @media (max-width: 576px) {
    font-size: 14px;

    .coffee-wars {
      font-size: 24px;
    }

    .green {
      font-size: 24px;
    }
  }
}
