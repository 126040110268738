.container {
  background: var(--roadmap-bg-color);
  font-family: Arial;
  font-weight: bolder !important;

  .main {
    padding: 4rem 0 10rem;

    .title {
      font-size: var(--text-diaplay2);
      text-transform: uppercase;
    }

    .text-sub {
      h3 {
        font-size: var(--text-xl);
        font-weight: 700;
      }
    }

    .arrow {
        width: 70%;
    }

    .date {
      font-size: var(--text-lg);
      letter-spacing: 4px;
    }
  }

  @media (max-width: 1200px) {
    .main {
      padding: 0 0 50px;

      .title {
        font-size: var(--text-xl);
      }

      .text-sub {
        h3 {
          font-size: var(--text-lg);
        }
      }
    }
  }
}
