.container {
  .item-title {
    font-size: var(--text-lg);
  }

  img {
    min-height: 150px;
  }

  .heading {
    font-size: var(--text-sm);
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fbf9f8;
    font-family: "Acier-display";
    text-align: center;

    .title {
      font-size: var(--text-40);
      letter-spacing: 5px;
      color: #fefefc;
      font-weight: 800;
      font-family: "Blenny";
      text-align: center;
    }

    h5 {
      font-size: var(--text-md);
      letter-spacing: 4px;
      line-height: 1;
      color: #fb4d01;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    .heading {
      .title {
        font-size: var(--text-xl);
        letter-spacing: 5px;
      }
    }
    .item-title {
      font-size: var(--text-lg);
    }
  }
}
