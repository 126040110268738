.container {
  font-family: "Acier-display";
  font-size: 25px;

  .logo {
    top: 0;
    left: 0;

    img {
      max-width: 150px;
    }
  }
}
