.container {
  background: var(--team-bg-color);
  min-height: 100vh;

  .main {
    padding: 4rem 0 10rem;

    .title {
      font-size: var(--text-diaplay2);
      text-transform: uppercase;
    }

    .keep-up-text {
      font-size: var(--text-xxl);
      text-transform: uppercase;
      filter: drop-shadow(0.052px 0.999px 2px #040300);
    }

    .keep-up-sub {
      h3 {
        font-size: var(--text-lg);
      }
    }

    .text-sub {
      h3 {
        font-size: var(--text-xl);
        font-weight: 700;
      }
    }

    .team-item {
      img {
        filter: drop-shadow(0.314px 5.992px 3.5px rgba(0, 0, 0, 0.89));
        border-radius: 2px;
        max-height: 350px;
        min-height: 350px;
        object-fit: contain;
      }

      .name {
        font-size: var(--text-lg);
        filter: drop-shadow(0.262px 4px 2px rgba(0, 0, 0, 0.89));
        line-height: 1;
      }

      .position {
        font-size: var(--text-20);
        line-height: 1;
      }

      .team-description {
        font-size: var(--text-md);
        line-height: 1.2;
      }

      .link-btn {
        background: #f3ce13;
        padding: 5px 10px;
        border-radius: 5px;
        min-width: 80px;
        line-height: 1;
        letter-spacing: -1px;
      }
    }

    .description {
      font-size: var(--text-lg);
      word-spacing: 1px;
    }

    .discord-btn {
      a {
        background: #5865f2;
        line-height: 1;
        padding: 13px 20px 10px;
        font-size: var(--text-md);
      }
    }
  }

  @media (max-width: 768px) {
    .main {
      padding: 0 0 50px;

      .title {
        font-size: var(--text-xl);
      }
    }
  }
}
